import React, { PureComponent } from "react";
import AOS from 'aos'
import Layout from '../components/Layout';
import ResearchSection from '../components/ResearchSection';
import styles from './styles.module.css'
import withLoading from '../hocs/withLoading'

class ResearchPage extends PureComponent {
    componentDidMount = () => {
        AOS.init({
            duration: 700,
            offset: 120,
        })
    }

    render() {
        const { pageContext, loading } = this.props

        return (
            <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
                <div className={styles.background}>
                    <ResearchSection/>
                </div>
            </Layout>
        )
    }
}

export default withLoading(ResearchPage, styles.background)